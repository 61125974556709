import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Globals } from '../providers/globals';

import { BaseProvider } from './base-provider';

@Injectable()
export class ConsumerMoneyOrderHandlerProvider extends BaseProvider{
	url: any;
	order: any; 
	allOrders: any = [];
	
	constructor(
		public http: HttpClient,
		public globals: Globals,
	){
		super();
	}

	getOrders(params){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyOrder' + 
		  									  '/business/' + params.businessId +
											  '/page/' + params.page +
											  '/size/' + params.size;

			this.http.get(
				this.url					
			)
			.subscribe(
				(data:any) => {
					this.list = data.Data;
					
					resolve(data);
				},
				err => {reject(err)}
			);
		});       	
  	}	 

	getOrdersForAllBusinesses(){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyOrder' + 
											  '/page/' + this.page +
											  '/size/' + this.size;

		    this.http.get(
		        this.url		        
		    )
		    .subscribe(
		    	(res:any) => {
					this.pageData(res.Data)

					resolve(this.list);						         
		    	}
		    );       
		});   
  	}	

  	getOrdersForAllBusinessesByWhiskey(){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyOrder' + 
		  									  '/whiskey/' + this.globals.whiskeyId +
											  '/page/' + this.page +
											  '/size/' + this.size;

		    this.http.get(
		        this.url		        
		    )
		    .subscribe(
		    	(res:any) => {
					this.pageData(res.Data)

					resolve(this.list);						         
		    	}
		    );       
		});   
  	}	  	  	

	getOrderDetail(id){		  	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyOrder/' + id;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				data => {						
					resolve(data);
				},
				err => {reject(err)}
		   	);  			
		});     
  	}	  	

  	 async checkin(id){
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerMoneyOrder/checkIn/' + id +
		  				'/whiskey/' + this.globals.whiskeyId;

		    this.http.post(
		        this.url,
		        {}			        
		    )
		    .subscribe(
				(data:any) => {						
					resolve(data);
				},
				err => {reject(err)}
		   	);  
		});     		
  	}
}
