import { Component } from '@angular/core';
import { NavController, LoadingController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

import { ConsumerMoneyOrderHandlerProvider } from '../../providers/consumer-money-order-handler';
import { ConsumerOrderHandlerProvider } from '../../providers/consumer-order-handler';
import { ConsumerBusinessHandlerProvider } from '../../providers/consumer-business-handler';
import { ChatOrderMessageListPage } from '../chat-order-message-list/chat-order-message-list';
import { ConsumerOrderRegistrationDetailPage } from 'src/app/pages/consumer-order-registration-detail/consumer-order-registration-detail.page';

import { Globals } from '../../providers/globals';
import { BaseListPage } from '../../providers/base-list-page';

@Component({
  selector: 'page-consumer-money-order-detail',
  templateUrl: './consumer-money-order-detail.html',
  styleUrls: ['./consumer-money-order-detail.scss'],
})
export class ConsumerMoneyOrderDetailPage extends BaseListPage {
	products: any = []; 
	registrations: any = [];

	order: any = {};
	logs: any = [];
	taxes: any = [];
	delivery: any = {};
	
	filePath: any;
	id: any = 0;

	showCloseButton: boolean = false;
	showFooter: boolean = false;

	constructor(
		public modalCtrl: ModalController,
		public router: Router,
		public route: ActivatedRoute,
		public loadingCtrl: LoadingController,
		public navCtrl: NavController, 
		public globals: Globals,
		public consumerOrderHandlerProvider: ConsumerOrderHandlerProvider,	
		public consumerBusinessHandlerProvider: ConsumerBusinessHandlerProvider,	
		public mainService: ConsumerMoneyOrderHandlerProvider) {
		super();
	}

	ngOnInit() {   
		this.filePath = this.globals.fileUrl;
		this.showFooter = true;

		this.id = this.mainService.order? this.mainService.order.Id : this.route.snapshot.paramMap.get('id');

		this.load(()=>{

      this.stopWorking();
    }); 
	}

	ionViewDidEnter() {
    console.log("this.globals.orderId")
    console.log(this.globals.orderId)

		if(this.globals.orderId){
			this.showCloseButton = true;
			
      this.globals.chatId = null;
      this.globals.orderId = null;
			
	    console.log("this.globals.orderId")
	    console.log(this.globals.orderId)			
		}
	}  		

	close(){
	  this.modalCtrl.dismiss({
	    'finishOrder': false
	  });       
	}

	goBack(){
		this.navCtrl.pop();
		this.showFooter = false;
	}

	doRefresh(refresher) {
		this.isRefreshing = true;
		this.params.page = 1;
	
		this.load(()=>{
			this.isRefreshing  = false;

		  	refresher.target.complete();

		  this.stopWorking();
		});
	}  		

	async checkin(){
		const loader = await this.loadingCtrl.create({
			message: "Checkin in...",
			showBackdrop: true
		});

		await loader.present(); 

		this.mainService
		.checkin(this.id)
		.then(async (resp:any)=>{
			await loader.dismiss(); 
			
			this.load(()=>{

	      this.stopWorking();
	    }); 
		});
	}

	load(cb){
		return new Promise( (resolve, reject) => {
			// this.isLoading = (!this.isRefreshing && !this.isFetchingMore) ? true : false;

			this.startWorking("loading");

			this.mainService
			.getOrderDetail(this.id)
			.then((resp:any)=>{
				this.isLoading = false;

				this.order = resp.Data.order;					
					
				this.logs = resp.Data.log;
				this.taxes = (this.order.TaxesJSON ? JSON.parse(this.order.TaxesJSON) : []);

				this.delivery = (this.order.DeliveryAddressJson ? JSON.parse(this.order.DeliveryAddressJson) : {});

				if(this.order.GroupJSON){
					this.products = JSON.parse(this.order.GroupJSON);	

					var splitProducts = [];

					this.products[0].products.forEach((product)=>{
						if(!product.FormData){
							for (var i = 0; i<product.Count;i++) {
								splitProducts.push(product);
							}							
						}
					});

					this.products = splitProducts;					
				}
				
				this.registrations = resp.Data.registrations;

				if(typeof cb == "function")
					cb()		

				resolve(null);

			});
		});
	}		

	async viewRegistration(registration){
    var modal = await this.modalCtrl.create({
      component:ConsumerOrderRegistrationDetailPage,
      componentProps: {
        formData: JSON.parse(registration.FormDataJson),
        productName: registration.ProductName,
        productDescription: registration.ProductDescription,
        formId: registration.FormId,
      }
    });
    
    await modal.present();		
	}

	goToOrderChat(order) {
		this.router.navigate([`chat/order/${order.Id}`]);
	}
}
