import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { StorageService } from 'src/app/providers/storage.service';
import { BaseService } from './base-service';

@Injectable()
export class CacheService extends BaseService {
	lastCacheCheck: any;

	constructor(
		public http: HttpClient,
		public globals: Globals,
		public storage: StorageService,
	) {
		super();

		this.baseUrl = "cacheData";
	}

	checkCache(cacheName){	
		return new Promise( (resolve, reject) => {
			this.storage.get(cacheName).then((cache:any) => {
				let version = "";

				if(cache){
					cache = JSON.parse(cache);
					version = cache.Version;
				}

				if(this.canPerformCacheCheckup() || !cache){
				  	this.url = this.globals.dataUrl + this.baseUrl + 
			  					'?cacheName=' + cacheName +
			  					'&version=' + version;

				    this.http.get(this.url)
				    .subscribe((res:any) => {
				    	var dataToReturn;

				    	if(res.Data){
				    		dataToReturn = JSON.parse(res.Data.CacheData);

				    		this.storage.set(cacheName, res.Data);	
				    	}else{
				    		dataToReturn = JSON.parse(cache.CacheData);
				    	}

						resolve(dataToReturn)
				    });  	
				}else{
					resolve(JSON.parse(cache.CacheData))
				}		
			});			
	  	});	
	}

	getCacheDataFromLs(cacheName){
		return new Promise( (resolve, reject) => {
			this.storage.get(cacheName).then((cache:any) => {
				resolve(cache);
			});
		});			
	}

	canPerformCacheCheckup(){
		var past = new Date(this.lastCacheCheck).getTime();

		var minutes = 1000 * 60 * 1; //One minute 
		var isPast = (new Date().getTime() - past < minutes) ? false : true;		

		if(isPast){
			this.lastCacheCheck = (new Date().getTime());	
		}		

		console.log("isPast");
		console.log(isPast);

		return (isPast? true : false);
	}
}
