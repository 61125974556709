import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { Utils } from '../helpers/utils';

import { BusinessProductPaymentManagerHandler } from '../providers/business-product-payment-handler';
import { ConsumerOrderHandlerProvider } from '../providers/consumer-order-handler';

@Injectable()
export class ProductModifierHandler {
	url: any;
	token: any;

	editModifiers: any = [];

	modifiers: any = [];
	modifierRules: any = [];  	
	modifierSelectionIsValid: boolean = true;
	
	totalModifierAmount: any = 0;

	constructor(
		public http: HttpClient,
		public globals: Globals,
		public utils: Utils,	
        public businessProductPaymentManagerHandler: BusinessProductPaymentManagerHandler,
        public consumerOrderHandlerProvider: ConsumerOrderHandlerProvider,        
	) {
		console.log('Hello ProductModifierHandlerProvider Provider');
	}

	clear(){
		this.modifiers = [];
		this.modifierRules = [];		
		this.editModifiers = [];
	}	

  	getModifiersByProductId(params){	
  		this.url = this.globals.dataUrl + 'businessProductPayment/modifiers/' + params.id;

	    this.http.get(
	        this.url		        
	    )
	    .subscribe((res:any) => {
			this.modifiers = res.Data.modifiers;
			this.modifierRules = res.Data.modifierRules;

			if(typeof params.cb == "function") params.cb(res.Data);
	    });   
  	} 

  	getModifiersByProductIdPromise(params){	
  		return new Promise( (resolve, reject) => {
	  		this.url = this.globals.dataUrl + 'businessProductPayment/modifiers/' + params.id;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe((res:any) => {
				this.modifiers = res.Data.modifiers;
				this.modifierRules = res.Data.modifierRules;

				resolve(null);
		    });   
		});       		    	
  	}   	

  	getModifiersByProductIdFromCache(params){	
  		return new Promise( (resolve, reject) => {
	  		this.url = this.globals.dataUrl + 'businessProductPayment/modifiers/' + params.id;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe((res:any) => {
				this.modifiers = res.Data.modifiers;
				this.modifierRules = res.Data.modifierRules;

				resolve(null);
		    });   
		});       		    	
  	}     	

  	getRulesByGroupId(groupId){
  		let group = this.modifierRules.find((modifier)=>{ return modifier.ModifierGroupId == groupId; })

  		let rules = (group.RulesJson?JSON.parse(group.RulesJson):"");

  		return rules;
  	}

	printRules(category){
		let rules = this.getRulesByGroupId(category.ModifierGroupId);		
		let rulesArray = [];
		let rulesText = "";

		if(rules.required==true){
			rulesArray.push("Required")
		}

		if(rules.min>0 && rules.max>0){

			if(rules.min == rules.max){
				rulesArray.push(`Pick ${rules.min}`)	
			}else{
				rulesArray.push(`Pick ${rules.min} to ${rules.max}`)		
			}
			
		}else if (rules.min>0 && rules.max==0){
			rulesArray.push(`Pick ${rules.min} minimun`)
		}else if (rules.min==0 && rules.max>0){
			rulesArray.push(`Pick up to ${rules.max}`)
		}

		if(rules.complimentary>0){
			rulesArray.push(`Includes ${rules.complimentary}`)	
		}

		if(rulesArray.length>0){
			rulesText = this.utils.concatenateListNoAttribute(rulesArray, " - ")
		}

		return rulesText;
	}  	

	setModifierGroupValidity(modifierGroupId, isValid){
		let gotFalse = false;

		this.modifierRules.forEach((mod)=>{
			if(mod.ModifierGroupId==modifierGroupId){
				mod.isValid = isValid;
			}

			if(!mod.isValid){
				gotFalse = true;
			}
		});

		if(gotFalse){
			this.modifierSelectionIsValid = false;	
		}else{
			this.modifierSelectionIsValid = true;	
		}	

		console.log("this.modifierSelectionIsValid")
		console.log(this.modifierSelectionIsValid)
	}

	sumSelectedModifiers(modifierGroupId, amount, modifiers){
		this.totalModifierAmount = 0;

		this.modifierRules.forEach((mod)=>{
			if(mod.ModifierGroupId==modifierGroupId){
				mod.selectedAmount = amount;
				mod.modifiers = modifiers;
			}

			if(mod.selectedAmount>0){
				this.totalModifierAmount += mod.selectedAmount;
			}			
		});		

		this.businessProductPaymentManagerHandler.setModifierAmount(this.totalModifierAmount);
	}

	returnSelectedModifiers(){
		let modifiers = [];

		this.modifierRules.forEach((rule)=>{
			if(rule.modifiers){
				rule.modifiers.forEach((modifier)=>{
					modifiers.push(modifier);
				});				
			}
		});

		return modifiers;
	}

	prepProductWithModifiers(product){
		let newProduct: any = {Modifiers:[], ModifierAmount:0};

		Object.assign(newProduct, product)

		newProduct.Modifiers = this.returnSelectedModifiers();
		newProduct.ModifierAmount = this.totalModifierAmount;

		this.totalModifierAmount = 0;

		return newProduct;
	}

	getEditProductModifiersByGroup(groupId){
		if(!this.editModifiers){
			return this.editModifiers;
		}else{
			return this.editModifiers.filter((mod)=>{
				return mod.ModifierGroupId == groupId;
			});			
		}
	}	
}
