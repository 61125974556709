import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from './base-service';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';

@Injectable()
export class StadiumSeatSelectService extends BaseService {	
	stadiumData: any;

	selectedSectionName: any;
	selectedRowName: any;

	selectedSection: any;
	selectedRow: any;
	selectedSeat: any;

	sections: any = [];
	rows: any = [];
	seats: any = [];
	levels: any = [];

	scanEvent = new Subject();

	constructor(
		public http: HttpClient,
		public globals: Globals,
	) {
		super();
	}

	seatConfigured(){
		if(
			this.selectedSectionName &&
			this.selectedRowName &&
			this.selectedSeat
		){
			return true;
		}else{
			return false;
		}
	}

	loadConfiguration(seatConfiguration){
		this.resetForLevelSelect();

		this.selectedSectionName = seatConfiguration.section;
		this.selectedRowName = seatConfiguration.row;
		this.selectedSeat = seatConfiguration.seat;

		this.getSections();

		this.selectSectionObject(this.selectedSectionName);

		this.getRows();

		this.selectRowObject(this.selectedRowName);

		this.getSeats();
	}

	getSections(){
		this.sections = [];

		this.stadiumData.forEach((section)=>{
			this.sections.push(section.Name);
		});
	}	

	selectSectionObject(sectionName){
		var foundLevel = null;

		this.stadiumData.forEach((section)=>{
			if(section.Name == sectionName){
				this.selectedSection = section;		
				this.selectedSectionName = section.Name;
				
				return true;		
			}
		});		
	}	

	getRows(){
		this.sections = [];

		this.selectedSection.Rows.some((row)=>{
			this.rows.push(row.Name);
		})
	}	

	selectRowObject(rowName){
		var foundLevel = null;

		this.selectedSection.Rows.some((row)=>{
			if(row.Name == rowName){
				this.selectedRow = row;		
				this.selectedRowName = row.Name;
				
				return true;		
			}
		});		
	}		

	getSeats(){
		this.seats = [];

		this.selectedRow.Seats.some((seat)=>{
			this.seats.push(seat);
		})
	}		

	resetForLevelSelect(){
	    this.sections = [];
	    this.rows = [];
	    this.seats = [];

		this.selectedSectionName = '';
		this.selectedRowName = '';

	    this.selectedSection = ''; 
	    this.selectedRow = ''; 
	    this.selectedSeat = '';  		
	}

	resetForSectionSelect(){
	    this.rows = [];
	    this.seats = [];

		this.selectedRowName = '';

	    this.selectedRow = ''; 
	    this.selectedSeat = '';  		
	}

	resetForRowSelect(){
	    this.seats = [];

	    this.selectedSeat = '';  		
	}	

	getSeatById(seatId){
		return new Promise( (resolve, reject) => {
			var url = this.globals.dataUrl + 'stadium/seat?id=' + seatId;

			this.http.get(
				this.url
			)
			.subscribe((res:any) => {						
				resolve(res.Data);
			});   
		});				
	}
}
