import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Globals } from '../providers/globals';
import { BaseProvider } from '../providers/base-provider';
import { CacheService } from 'src/app/providers/cache.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConsumerOrderHandlerProvider extends BaseProvider {
	gotTaxes: boolean = false;
	taxes: any = [];
	taxAmount: any = 0;

	taxBreakDown: any  = [];
	deliveryFeeConfig: any = {}

	products: any = [];
	categories: any = [];
	selectedCategory: any;

	constructor(
		public http: HttpClient,
		public globals: Globals,
		public cacheService: CacheService,
	){
		super();

		this.baseUrl = "consumerOrderCreator";
	}

	selectCategory(category){
		this.selectedCategory = category;

		this.categories.forEach((cat)=>{
			cat.IsSelected = (cat.Id == category.Id) ? true : false;
		});
	}	

	clearFees(){
		this.deliveryFeeConfig = {};
	}

  	getProducts(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   '/subCategories/' + businessId;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					resolve(data.Data);

					this.products = data.Data;
				},
				err => {reject(err)}
		   	);  
		});    	
  	}

  	orderTypeId: any = 0;
  	
  	getProductsByOrderType(businessId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 'consumerOrderType/products/' + this.globals.mainBusinessId + 
		  									  '/orderType/' + this.orderTypeId;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					resolve(data.Data);

					this.products = data.Data;
				},
				err => {reject(err)}
		   	);  
		});    	
  	}  	

  	getProductsByCategory(categoryId){	
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   '/category/' + categoryId +
	    		   	   '/subCategories';

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  
		});    	
  	}

	placeOrder(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/business/' + params.order.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId;
	
			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	   	

	placeOrderV2(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/business/' + params.order.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId + '/v2';

			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}

	placeOrderV3(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/business/' + params.order.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId + '/v3';

			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	  	

	placeOrderV4(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/business/' + params.order.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId + '/v4';

			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	  	

	placeDebitOrder(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/debit/business/' + params.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId;

			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}	   	

	placeDebitOrderV2(params){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 
					   this.baseUrl + 
					   '/debit/business/' + params.order.BusinessId +
					   '/whiskey/' + this.globals.whiskeyId + '/v2';

			this.http.post(
				this.url,
				params,									
			)
			.subscribe(
				(data:any) => {
					resolve(data);
				},
				err => {reject(err)}
			);
		});       
	}			

	getTaxes(businessId, cats){
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   '/tax/' + businessId + '?categoryIds=' + cats;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					this.gotTaxes = true;
					this.taxes = data.Data;

					this.taxes.forEach((tax)=>{
						this.taxAmount += tax;
					});

					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  
		});   		
	}

	setTaxAmount(total){
		var totalTaxAmount = 0;
		this.taxBreakDown = [];
		
		this.taxes.forEach((tax)=>{
			let totalCalc = (tax.TaxPercent/100)*total;

			this.taxBreakDown.push({
				taxName: tax.TaxName,
				taxAmount: totalCalc
			});

			totalTaxAmount+=totalCalc;
		});		

		return totalTaxAmount;
	}

	getDeliveryFee(cityId, businessId){
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   `/delivery/fee/${cityId}/business/${businessId}`;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {
					this.deliveryFeeConfig = data.Data;

					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  		
		});   		
	}	

	verifyPaymentMethod(cardPrefix, categories){
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   `/campaign/bppr?cardPrefix=${cardPrefix}&categories=${categories}&businessId=${this.globals.mainBusinessId}`;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {

					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  		
		});   		
	}		

	applyCouponCode(couponCode){
		return new Promise( (resolve, reject) => {
		  	this.url = this.globals.dataUrl + 
		  			   this.baseUrl + 
	    		   	   `/campaign/apply?couponCode=${couponCode}&businessId=${this.globals.mainBusinessId}`;

		    this.http.get(
		        this.url			        
		    )
		    .subscribe(
				(data:any) => {

					resolve(data.Data);
				},
				err => {reject(err)}
		   	);  		
		});   		
	}			

	loadMenuFromCache(){
		return new Promise( (resolve, reject) => {
		    this.cacheService
		    .checkCache("menu-business-" + this.globals.mainBusinessId)
		    .then((data:any)=>{
		    	try{
		    		data = data[0];

					var categories = JSON.parse(data["categories"]);			

					for(let category of this.categories){
						category.Initial = category.CategoryName.charAt(0)
					}

					this.categories = categories;
					this.products = JSON.parse(data["products"]);
		    	}catch(e){
		    		console.log(e);
		    	}
				
				resolve(true);       
		    });		
		});   			
	}	
}