import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Globals } from '../providers/globals';
import { BaseService } from './base-service';
import { StorageService } from 'src/app/providers/storage.service';

@Injectable()
export class AuthProvider {
	url: any;
	user: any;
	returnUrl: any;
	
	constructor(
		public platform: Platform, 
		public http: HttpClient,
		public storage: StorageService,
		public globals: Globals,
		) {
		console.log('Hello AuthProvider Provider');
	}		
	
	ping(){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'auth/ping';

			console.log("in ping");

		    this.http
		    .get(
		    	this.url,
		    )
		    .subscribe(
		        data => {
		        	console.log("in ping data");
			    	resolve(true);
		       	},
		        err => {
		        	console.log("in ping err");
		        	console.log(err);
		        	reject(err)
		        }
		    );
		});
	}

	login(data){	
		let body =  'grant_type=' + data.grant_type +
					'&username='+ data.username.trim() + this.globals.whiskeyId +
					'&password='+ data.password;

		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'token';

		    this.http
		    .post(this.url, body)		   
		    .subscribe(
		        (data:any) => {
		        	this.globals.loginData = data;

			        this.storage
			        .set('loginData', {access_token: this.globals.loginData.access_token})
			        .then(()=>{

			        	this.getRole((data)=>{			            
			            	this.storage.set('role', data);

			            	resolve(data);        
			          	});

			        });
		       	},
		        err => {reject(err)}
		    );
		});
	}

	isLoggedIn(){
		return new Promise( (resolve, reject) => {
			this.storage.get('loginData').then((loginData)=>{
				if(loginData){
					resolve(true);
				}else{
					resolve(false);
				}
			});			
		});			
	}

	fbLogin(result){	
		// let body =  { 
		// 				provider: "Facebook", 
		// 		       	token: result.authResponse.accessToken 
		// 		    };

		let body =  { 
						Provider: "Facebook", 
				       	Token: result 
				    };

		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/JwtFromProviderAccessToken';

		    this.http
		    .post(this.url, body)		   
		    .subscribe(
		        (data:any) => {
		        	this.globals.loginData.access_token = data;
			        this.storage.set('loginData', {access_token: this.globals.loginData.access_token}).then(()=>{

			          this.getRole((data)=>{
			            this.storage.set('role', data);
			            
			            resolve(data)
			          })
			        })
		        },
		        err => {reject(err)}
		    );
		});
	}	

	register(data){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/register';

		    this.http
		    .post(this.url, data)
		    .subscribe(
		        data => {resolve(data)},
		        err => {reject(err)}
		    );
		});
	}   

	logMessage(msg){
		// return new Promise( (resolve, reject) => {
		// 	this.url = this.globals.dataUrl + 'auth/log';

		//     this.http
		//     .post(this.url, {Message:msg})
		//     .map(res=> {
		//     	return res;
		//     })
		//     .subscribe(
		//         data => {resolve(data)},
		//         err => {reject(err)}
		//     );
		// });
	}   

	registerBusiness(data){
		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/register/business';

		    this.http
		    .post(this.url, data)
		    .subscribe(
		        data => {resolve(data)},
		        err => {reject(err)}
		    );
		});
	}  	
	
	getRole(cb){
		this.url = this.globals.dataUrl + 'account/role';

	    this.http.get(
	    	this.url
	    )
		.subscribe(data => {
	        if(typeof cb == "function") cb(data);
	    });    
	}

	requestPasswordChange(data){

		let body =  'username='+ data.username;

		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/requestPasswordChange';

		    this.http
		    .post(this.url, data)
		    .subscribe(
		        data => {resolve(data)},
		        err => {reject(err)}
		    );
		});
	}   	

	validatePasswordCode(data){
		let body =	'username='+ data.username +
					'&passwordCode='+ data.passwordCode;

		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/validatePasswordCode';

		    this.http
		    .post(this.url, data)
		    .subscribe(
		        (data:any) => {resolve(data.Data)},
		        err => {reject(err)}
		    );
		});
	}

	changePassword(data){
		let body =	'grant_type=' + data.grant_type +
					'&password='+ data.password +
					'&username='+ data.username +
					'&passwordCode='+ data.passwordCode;

		return new Promise( (resolve, reject) => {
			this.url = this.globals.dataUrl + 'account/whiskey/' + this.globals.whiskeyId + '/changePassword';

		    this.http
		    .post(this.url, data)
		    .subscribe(
		        (data:any) => {resolve(data.Success)},
		        err => {reject(err)}
		    );
		});
	}

	getUserInfo(cb){	
	  	this.url = this.globals.dataUrl + 'account/user';

	    this.http.get(
	        this.url
	    )
	    .subscribe(
	    	(res:any) => {
	    		this.user = res.Data;
	    		
	    		this.storage.set('uid', this.user.IdentityUserId);

	          	if(typeof cb == "function") cb(res.Data);
	    	}
	    );   
  	}

  	logout(){
	    this.storage.remove('loginData');
	    this.storage.remove('paymentMethods');
	    this.storage.remove('role');		  		
  	}
}
